import React, { useEffect, useState } from "react";
import { Button, message, Modal, Upload } from "antd";
import { UploadOutlined, SearchOutlined } from "@ant-design/icons";
import SelectDocuments from "./SelectDocuments";
import { createDocuments } from "utils/Actions";
import { CommonError } from "utils/Helper/CommonError";
import { saveFileToS3 } from "utils/Helper/commonMethods";

export default function AddNewDocument(props) {
  const {
    setAddNewDoc,
    addNewDoc,
    userOrganizationId,
    currentIdToken,
    folderKey,
    parentKey,
    treeData,
    folderArray,
    handleDocCustom = () => { },
    handleCustomForm = () => { },
  } = props;
  const [selectDoc, setSelectDoc] = useState(false); // selected document listing
  const [fileList, setFileList] = useState([]); // Upload selected files listing
  const [uploadedData, setUploadedData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Upload validation function :: begin
  const uploadProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isLt10M = file.size / 1024 / 1024 < 10;
      const validFile =
        file.type === "pdf/jpg/jpeg/png" ||
        file.name.endsWith(".pdf") ||
        file.name.endsWith(".jpg") ||
        file.name.endsWith(".jpeg") ||
        file.name.endsWith(".png");

      const addedFile = fileList.findIndex((item) => item.name === file.name);
      // s3UploadFile('', file.name);

      if (addedFile !== -1) {
        message.error("This file is already added!");
        return false;
      }

      if (!isLt10M) {
        message.error("File must be smaller than 10MB!");
        return false;
      }

      if (!validFile) {
        message.error("Only PDF or Image files are allowed!");
        return false;
      }
      setFileList((prevFile) => [...prevFile, file]);
      return false;
    },
    fileList,
  };
  // Upload validation function :: end
  useEffect(() => {
    if (!selectDoc) {
      setUploadedData([]);
    }
  }, [selectDoc]);

  // Upload file :: begin
  const uploadFiles = () => {
    try {
      setLoading(true);
      const S3Key =
        folderKey === null
          ? "Documents"
          : folderKey !== null && parentKey === null
            ? `Documents/${folderKey}`
            : `Documents/${parentKey}/${folderKey}`;

      fileList.map(async (file, index) => {
        const data = await saveFileToS3(file, S3Key);
        const input = {
          fileType: file?.type,
          name: file?.name,
          fileName: data?.key,
          organizationID: userOrganizationId,
          parentId: folderKey,
          createdAt: new Date(),
        };

        await createDocuments(input, currentIdToken).then((result) => {
          setUploadedData((prevData) => [...prevData, result]);

          if (file?.uid === fileList[fileList.length - 1]?.uid) {
            setAddNewDoc(false);
            setSelectDoc(true);
            setLoading(false);
            setFileList([]);
            // setUploadedData([]);
          }

          message.success("Document successfully added");
        });
      });
    } catch (error) {
      CommonError(error);
      setLoading(false);
    }
  };

  // Upload file :: end

  return (
    <>
      <Modal
        title="Add New Document"
        width={350}
        centered
        className="modal"
        maskClosable={true}
        open={addNewDoc}
        onCancel={() => {
          setAddNewDoc(false);
          setFileList([]);
          // setUploadedData([]);
        }}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              uploadFiles();
            }}
            loading={loading}
            disabled={fileList.length === 0}
          >
            Save
          </Button>,
        ]}
      >
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="text-sm my-10">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-text">
            <Button type="primary" className="dark-primary-btn">
              <SearchOutlined /> Browse
            </Button>
          </p>
        </Upload.Dragger>
      </Modal>

      <SelectDocuments
        uploadedFiles={uploadedData}
        selectDoc={selectDoc}
        setSelectDoc={() => setSelectDoc()}
        userOrganizationId={userOrganizationId}
        currentIdToken={currentIdToken}
        treeData={treeData}
        folderArray={folderArray}
        handleDocCustom={(data) => {
          if (selectDoc) {
            handleDocCustom(data);
          }
        }}
        handleCustomForm={(data) => handleCustomForm(data)}
      />
    </>
  );
}
