import { Col, Row, Select, Space } from "antd";
import ErrorMessageComponent from "Common/Components/Errors/ErrorMessageComponent";
import { Formik, Form } from "formik";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useInsertionEffect,
} from "react";
import { useSelector } from "react-redux";
import { getCurrentToken, getOrganizationId } from "store/slices/loginSlice";
import { itemsByItemTypeId, itemTypesByProjectId } from "utils/Actions";
import { debounceFn } from "utils/Helper/commonMethods";
import {
  listItemsSearch,
  listItemsTypesSearch,
  listProjectsSearch,
} from "utils/RESTApi";
import * as Yup from "yup";
import { fetchData } from "../commonFunction";

const AssociateForm = (props) => {
  const {
    actionType,
    projectData,
    itemTypeData,
    formName,
    handleSubmitForm = () => {},
    dataToEdit = [],
    bulkEdit,
    documentId,
    setFormRef,
  } = props;

  const userOrganizationId = useSelector(getOrganizationId);
  const formikRef = useRef();

  useInsertionEffect(() => {
    setFormRef(formikRef);
  }, [formikRef]);

  const [itemTypeList, setItemTypeList] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [nextToken, setNextToken] = useState();
  const [itemTypeValues, setItemTypeValues] = useState([]);
  const [itemsValue, setItemsValue] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedItemTypes, setSelectedItemTypes] = useState([]);

  const currentTokenId = useSelector(getCurrentToken);

  useEffect(() => {
    const projects = dataToEdit
      ?.map((item) => item?.projectId)
      .filter((item) => item !== null);

    if (dataToEdit.length > 0) {
      if (projects.length > 0) {
        setSelectedProjects(projects);
      } else {
        fetchItemTypes(false);
      }
    } else {
      setSelectedProjects([]);
      setItemTypeList([]);
    }
  }, [dataToEdit]);

  const filteredItemType = useMemo(() => {
    return formikRef?.current?.values?.itemType?.filter((item) =>
      itemTypeList.some((itemType) => itemType?.id === item)
    );
  }, [itemTypeList, formikRef?.current?.values?.itemType]);

  useEffect(() => {
    if (
      filteredItemType?.length !== formikRef?.current?.values?.itemType?.length
    ) {
      formikRef?.current?.setValues((prevValues) => ({
        ...prevValues,
        itemType: filteredItemType,
      }));
    }
  }, [filteredItemType, formikRef]);

  useEffect(() => {
    const itemTypes = dataToEdit
      ?.map((item) => item?.itemTypeId)
      .filter((item) => item !== null);

    if (dataToEdit && itemTypes?.length > 0) {
      setItemsList([]);

      for (let index = 0; index < itemTypes.length; index++) {
        const element = itemTypes[index];
        fetchItems(element, itemTypes);
      }
      // itemTypes.map((item) => {
      //   if (item !== null) {
      //     fetchItems(item);
      //   }
      // });
    }
  }, [dataToEdit]);

  useEffect(() => {
    if (
      itemTypeData &&
      dataToEdit.length === 0 &&
      formikRef?.current?.values?.project?.length === 0
    ) {
      setItemTypeList(itemTypeData);
    }
  }, [itemTypeData, dataToEdit]);

  const filteredItems = useMemo(() => {
    return formikRef?.current?.values?.items?.filter((item) =>
      itemsList.some((itemType) => itemType?.id === item)
    );
  }, [itemsList, formikRef?.current?.values?.items]);

  useEffect(() => {
    if (filteredItems?.length !== formikRef?.current?.values?.items?.length) {
      formikRef?.current?.setValues((prevValues) => ({
        ...prevValues,
        items: filteredItems,
      }));
    }
  }, [filteredItems, formikRef]);

  useEffect(() => {
    if (projectData) {
      setProjectList(projectData);
    }
  }, [projectData]);

  // "Projects list" :: begins
  let projectOptions = useMemo(() => {
    const options = projectList?.map((item) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    });

    return options;
  }, [projectList]);
  // "Projects list" :: end

  // "Items list" :: begins
  let itemsOptions = useMemo(() => {
    const options = itemsList?.map((item) => {
      return {
        label: item?.serialNumber,
        value: item?.id,
      };
    });

    return options;
  }, [itemsList]);
  // "Items list" :: end

  // "Item Types" :: begin
  let itemTypesOptions = useMemo(() => {
    const options = itemTypeList?.map((item) => {
      return {
        label: item?.number,
        value: item?.id,
      };
    });

    return options;
  }, [itemTypeList, projectList]);
  // "Item Types" :: begin

  // search function :: begin
  const handleCustomInputSearch = (searchText) => {
    debouncedCustomHandleInput(searchText);
  };

  const handleCustomSearch = (value, inputName) => {
    if (inputName === "project") {
      fetchData(
        userOrganizationId,
        listProjectsSearch,
        setLoadingData,
        setProjectList,
        setItemTypeList,
        setNextToken,
        value
      );
    } else if (inputName === "items") {
      fetchData(
        userOrganizationId,
        listItemsSearch,
        setLoadingData,
        setItemsList,
        setItemTypeList,
        setNextToken,
        value
      );
    } else {
      fetchData(
        userOrganizationId,
        listItemsTypesSearch,
        setLoadingData,
        setItemsList,
        setItemTypeList,
        setNextToken,
        value
      );
    }
  };

  const debouncedCustomHandleInput = debounceFn(handleCustomSearch, 200);
  // search function :: end

  // 'validation schema' :: begins
  const validationSchema = Yup.object().shape({
    itemType:
      actionType !== "Item"
        ? Yup.array()
            .min(1, "Item type is required") // Ensure at least one item is selected
            .required("Item type is required")
        : Yup.array().when(["project"], {
            is: (value) => value === null || value.length === 0, // Check if project is null or empty
            then: () =>
              Yup.array()
                .min(1, "Please select Project or Item Type")
                .required("Please select Project or Item Type"),
            otherwise: () => Yup.array().nullable(),
          }),
    items:
      actionType === "Item"
        ? Yup.array()
            .min(1, "Please select at least one item") // Ensure at least one item is selected
            .required("Please select items")
        : Yup.array().nullable(),
  });

  // 'validation schema' :: end

  // 'Initial values' :: begins
  const initialValues = {
    project:
      dataToEdit
        ?.map((item) => item?.projectId)
        ?.filter((item) => item !== null) || [],
    itemType:
      dataToEdit
        ?.map((item) => item?.itemTypeId)
        ?.filter((item) => item !== null) || [],
    items:
      dataToEdit
        ?.map((item) => item?.itemsId)
        ?.filter((item) => item !== null) || [],
    documentId: bulkEdit && documentId,
  };

  // 'Initial values' :: end

  // (handle submit function) :: begins
  const handleSubmit = (values, { setSubmitting, validateForm }) => {
    validateForm().then((errors) => {
      const hasErrors = Object.keys(errors).length > 0;

      if (hasErrors) {
        setSubmitting(false);
        return;
      }

      if (bulkEdit && documentId) {
        values.documentId = documentId;
      }

      // items in value
      // const valueItems = values?.items
      //   ?.map((items) => itemsList?.filter((value) => value.id === items))
      //   .flat();
      // // items in value

      // if (values?.itemType?.length > 1) {
      //   // itemType in value
      //   const valueItemTypes = valueItems
      //     ?.map((item) =>
      //       itemTypeList?.filter((value) => value.id === item?.itemTypeId)
      //     )
      //     .flat();
      //   const filteredItemType = valueItemTypes.filter(
      //     (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
      //   );
      //   // itemType in value

      //   // itemType in list
      //   const itemTypeIds = valueItems
      //     ?.map((item) =>
      //       values?.itemType?.filter((value) => value === item?.itemTypeId)
      //     )
      //     .flat();
      //   const filteredItemTypeIds = itemTypeIds.filter(
      //     (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
      //   );

      //   if (actionType === "Item") {
      //     values.itemType = filteredItemTypeIds;
      //   }
      //   // itemType in list

      //   if (values?.project?.length > 1) {
      //     // project in list
      //     const projectsIds = filteredItemType
      //       ?.map((itemType) =>
      //         values?.project?.filter((item) => item === itemType?.projectId)
      //       )
      //       .flat();
      //     const filteredProjectsIds = projectsIds.filter(
      //       (obj, index, self) =>
      //         index === self.findIndex((o) => o.id === obj.id)
      //     );
      //     values.project = filteredProjectsIds;
      //     // project in list
      //   }
      // }

      handleSubmitForm(values, formikRef); // Save form data
      // formikRef?.current?.resetForm();
    });
  };
  // (handle submit function) :: end
  // useEffect(() => {
  //   if (itemsList?.length === 0) {
  //   }
  // }, [itemsList]);

  useEffect(() => {
    if (selectedItemTypes.length > 0) {
      for (let index = 0; index < selectedItemTypes.length; index++) {
        const element = selectedItemTypes[index];

        fetchItems(element, selectedItemTypes);
      }
    }
  }, [selectedItemTypes]);

  // {Fetch function for Items} :: begins
  const fetchItems = async (itemTypeId, itemTypeArray) => {
    setLoadingData(true);
    try {
      let filter = {
        isDeleted: { ne: true },
      };

      if (itemTypeId) {
        const response = await itemsByItemTypeId(
          itemTypeId,
          filter,
          currentTokenId
        );

        setItemsList((prevItemList) => {
          const newItemTypes = response?.items || [];
          const combinedItemTypes = [...prevItemList, ...newItemTypes];

          return combinedItemTypes
            .filter(
              (item) => itemTypeArray.includes(item.itemTypeId) // Ensure item is associated with selected projects
            )
            .filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id) // Ensure uniqueness
            );
        });
      } else {
        setItemsList([]);
      }
    } catch (error) {
    } finally {
      setLoadingData(false);
    }
  };
  // {Fetch function for Items} :: end

  useEffect(() => {
    if (selectedProjects.length > 0) {
      for (let index = 0; index < selectedProjects.length; index++) {
        const element = selectedProjects[index];

        fetchItemTypes(true, element, selectedProjects);
      }
    }
  }, [selectedProjects]);

  // {Fetch function for ItemTypes} :: begins
  const fetchItemTypes = async (
    projectSelected = false,
    projectId,
    selectedProjectsArray
  ) => {
    let filter = {
      isDeleted: { ne: true },
    };

    if (projectSelected) {
      setLoadingData(true);
      try {
        if (projectId) {
          const response = await itemTypesByProjectId(
            projectId,
            filter,
            null,
            500,
            currentTokenId
          );

          setItemTypeList((prevItemTypeList) => {
            const newItemTypes =
              response?.data?.itemTypesByProjectId?.items || [];
            const combinedItemTypes = [...prevItemTypeList, ...newItemTypes];

            return combinedItemTypes
              .filter(
                (item) => selectedProjectsArray.includes(item.projectId) // Ensure item is associated with selected projects
              )
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.id === item.id) // Ensure uniqueness
              );
          });
        } else {
          setItemTypeList(itemTypeData);
        }
      } catch (error) {
      } finally {
        setLoadingData(false);
      }
    } else {
      fetchData(
        userOrganizationId,
        listItemsTypesSearch,
        setLoadingData,
        setItemsList,
        setItemTypeList,
        setNextToken
      );
    }
  };
  // {Fetch function for ItemTypes} :: end

  // {Delete deselected associate item} :: begin
  const deleteDeselected = (id) => {
    const result = itemTypeList.filter((obj) => obj?.Projects?.id !== id);

    setItemTypeList(result);
  };

  const deleteDeselectedItems = (id) => {
    const result = itemsList.filter((obj) => obj?.itemTypeId !== id);

    setItemsList(result);
  };
  // {Delete deselected associate item} :: end

  useEffect(() => {
    const itemTypes = itemTypesOptions
      ?.map((item) =>
        formikRef?.current?.values.itemType.filter(
          (type) => item.value === type
        )
      )
      .flat();

    setItemTypeValues(itemTypes);

    const items = itemsOptions
      ?.map((item) =>
        formikRef?.current?.values.items.filter((type) => item.value === type)
      )
      .flat();

    setItemsValue(items);
  }, [formikRef?.current?.values?.project, itemTypesOptions]);

  useEffect(() => {
    const items = itemsOptions
      ?.map((item) =>
        formikRef?.current?.values.items.filter((type) => item.value === type)
      )
      .flat();

    setItemsValue(items);
  }, [formikRef?.current?.values?.itemType, itemsOptions]);

  return (
    <Formik
      onSubmit={handleSubmit}
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ touched, handleChange, values, errors, setFieldValue }) => {
        return (
          <Form id={formName}>
            <Row gutter={bulkEdit && 16}>
              <Col span={bulkEdit ? 12 : 24}>
                <div className="mb-10">
                  <label htmlFor="project">Project</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      style={{
                        width: "100%",
                      }}
                      name="project"
                      mode="multiple"
                      allowClear
                      value={values.project ? values.project : []}
                      placeholder="Please select project"
                      onSearch={async (input) => {
                        handleCustomInputSearch(input, "project");
                      }}
                      onChange={(e) => {
                        setFieldValue("project", e);
                        setSelectedProjects(e);
                      }}
                      onClear={() => {
                        setSelectedProjects([]);
                        setFieldValue("itemType", []);
                        fetchItemTypes(false);
                      }}
                      onSelect={(e) => {
                        const projects = [];
                        projects.push(e);
                      }}
                      onDeselect={(e) => {
                        deleteDeselected(e);
                        setFieldValue("itemType", itemTypeValues);
                        setFieldValue("items", itemsValue);

                        if (values?.project?.length === 1) {
                          setItemsList([]);
                          setFieldValue("itemType", []);
                          setFieldValue("items", []);
                          fetchData(
                            userOrganizationId,
                            listItemsTypesSearch,
                            setLoadingData,
                            setItemsList,
                            setItemTypeList,
                            setNextToken
                          );
                        }
                      }}
                      loading={loadingData}
                      options={projectOptions}
                      autoFocus
                    />
                  </Space>
                </div>
              </Col>

              <Col span={bulkEdit ? 12 : 24}>
                <div className="mb-10">
                  <label htmlFor="itemType">Item Type(s)</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      name="itemType"
                      style={{
                        width: "100%",
                      }}
                      mode="multiple"
                      allowClear
                      value={values.itemType ? values.itemType : []}
                      placeholder="Please select item types"
                      onSearch={async (input) => {
                        handleCustomInputSearch(input, "itemType");
                      }}
                      onChange={(e) => {
                        setFieldValue("itemType", e);
                        setSelectedItemTypes(e);
                      }}
                      onClear={() => {
                        setSelectedItemTypes([]);
                        setFieldValue("items", []);
                        setItemsList([]);
                      }}
                      onSelect={(e) => {
                        // fetchItems(e);
                      }}
                      onDeselect={(e) => {
                        deleteDeselectedItems(e);
                        setFieldValue("items", itemsValue);

                        const filter = values?.itemType?.filter(
                          (item) => item !== e
                        );
                        setItemsList(
                          itemsList?.filter((items) => items?.itemTypeId !== e)
                        );
                        if (filter?.length === 0) {
                          setItemsList([]);
                        }

                        if (values?.itemType?.length === 1) {
                          setFieldValue("items", []);
                        }
                      }}
                      loading={loadingData}
                      options={itemTypesOptions}
                      autoFocus
                    />
                  </Space>
                  {touched?.itemType && (
                    <ErrorMessageComponent error={errors?.itemType} />
                  )}
                </div>
              </Col>
            </Row>

            {actionType === "Item" && (
              <Col span={bulkEdit ? 12 : 24}>
                <div className="mb-10">
                  <label htmlFor="items">Item(s)</label>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      name="items"
                      style={{
                        width: "100%",
                      }}
                      mode="multiple"
                      allowClear
                      value={values.items ? values.items : []}
                      placeholder="Please select items"
                      onSearch={async (input) => {
                        handleCustomInputSearch(input, "items");
                      }}
                      onChange={(e) => setFieldValue("items", e)}
                      loading={loadingData}
                      options={itemsOptions}
                      autoFocus
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Space>
                  {touched?.items && (
                    <ErrorMessageComponent error={errors?.items} />
                  )}
                </div>
              </Col>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssociateForm;
